/*=== GERAL ================================*/
@font-face {
  font-family: 'Gunar Thin';
  src: url('../../fonts/gunar-thin-webfont.woff2') format('woff2'),
    url('../../fonts/gunar-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --hue: 190;
  --primary-color: #d0fa94;
  --tamia-black: #21211a;
  --tamia-white: #fff;
  --tamia-hard-green: #9cdb37;
  --headline: #4d4d4d;
  --paragraph: hsl(206, 10%, 14%);
  --tamia-light-green: #f0fcd6;
  --brand-light: hsl(calc(var(--hue) - 22), 23%, 89%);
  --brand-light-2: hsl(calc(var(--hue) + 10), 14%, 97%);
  --brand-dark: hsl(var(--hue), 100%, 14%);

  --bg-light: #f0fcd6;

  --nav-height: 5.3rem;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Poppins';
  font-size: 1rem;
  overflow: overlay;
  background-color: var(--tamia-white);
  min-height: 100vh;
}

.wrapper {
  width: min(31.25rem, 100%);
  margin-inline: auto;
  padding-inline: 2.4rem;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
}

section {
  padding-block: 3.12rem;
}

section header h4 {
  font-size: 0.88rem;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.05rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

section header h2 {
  font-size: 1.87rem;
  line-height: 2.44rem;
  color: var(--paragraph);
}

section header h1 {
  color: var(--tamia-black);
  line-height: 130%;
  font-size: 0.94rem;
  margin: 0rem 0.62rem 1.5rem 0.62rem;
  font-family: 'Gunar Thin';
  font-weight: 800;
}

section .content p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: #2d2d2d;
}
#logo-header {
  display: flex;
  width: 10rem;
  height: 2.5rem;
}
.button {
  background: var(--primary-color);
  border: none;
  border-radius: 2.5rem;
  margin-bottom: 3.75rem;
  padding: 1rem 2rem;
  width: fit-content;
  color: var(--tamia-white);
  font-size: 0.88rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  text-decoration: none;
  transition: background 200ms;
}

.button:hover {
  background-color: var(--tamia-hard-green);
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

/*=== NAVIGATION =================================*/
#navigation {
  display: flex;
  background-color: var(--tamia-white);
  padding: 1.25rem;
  height: var(--nav-height);
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
  text-align: center;
}

#navigation .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#navigation button {
  background: none;
  border: none;
  cursor: pointer;
}

#navigation .menu,
#navigation .close-menu {
  position: fixed;
}
#navigation img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13rem;
  height: 2rem;
}

/*=== MENU-EXPANDED =================================*/
body.menu-expanded {
  overflow: hidden;
}

body.menu-expanded > :not(#navigation) {
  visibility: hidden;
}

.menu,
.close-menu,
body.menu-expanded .open-menu {
  opacity: 0;
  visibility: hidden;
}

body.menu-expanded .menu,
body.menu-expanded .close-menu {
  opacity: 1;
  visibility: visible;
}

.menu {
  transform: translateY(100%);
}

.menu a svg {
  margin-bottom: -0.31rem;
  margin-right: 0.37rem;
}
.menu-expanded a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-expanded .button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
body.menu-expanded .menu {
  top: 0;
  left: 0;
  background-color: var(--primary-color);
  width: 100vw;
  height: 100vh;
  padding-top: var(--nav-height);
  transition: transform 300ms;
  transform: translateY(0);
}

.menu ul:nth-child(1) {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 3.75rem;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 0.69rem;
}

.menu ul li a {
  color: var(--tamia-black);
  text-decoration: none;
}

.menu .button,
.button-contact,
#navigation .button-login {
  text-decoration: none;
  display: flex;
  margin: 3rem 0;
  width: 9rem;
  background-color: var(--tamia-black);
  color: #d0fa94;
  margin-inline: auto;
  padding: 0.62rem 1.44rem;
  border-radius: 10px;
  font-weight: 800;
  font-size: 15px;
  text-align: center;
}
.menu .button,
.button-contact,
#navigation .button-login {
  margin: 1.25rem;
}

body.menu-expanded .logo,
body.menu-expanded button {
  position: relative;
  z-index: 100;
}

body.menu-expanded button path {
  stroke: var(--tamia-black);
}

/*=== #HOME =================================*/
#home {
  margin-top: 0.62rem;
  padding-top: calc(2.56rem + var(--nav-height));
  background: url(./assets/fundoDobra1.png) center no-repeat, var(--bg-light);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

#home::before {
  content: '';
  width: 100%;
  height: calc(76% + var(--nav-height));
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

#home .button {
  background-color: var(--tamia-black);
  color: #d0fa94;
  margin-inline: auto;
  padding: 0.62rem 1.44rem;
  margin-bottom: 1.25rem;
  border-radius: 10px;
  font-weight: 800;
  font-size: 8px;
  text-align: center;
}

#home p {
  font-size: 0.75rem;
  line-height: 150%;
  font-weight: 400;
  color: var(--tamia-black);
  margin: 2rem 0.62rem;
}

#home h1 {
  font-size: 0.8rem;
  line-height: 150%;
  font-weight: 600;
  color: var(--tamia-black);
  font-family: 'Gunar Thin';
}
#home .hr-top {
  width: 40%;
  background-color: #9cdb37;
  height: 3px;
  border: none;
  margin: 5px 10px;
}

#home .hr-bottom {
  width: 40%;
  background-color: #9cdb37;
  height: 3px;
  position: relative;
  left: 55%;
  border: none;
  bottom: 20px;
}

#home img {
  width: 17rem;
  display: block;
  margin-inline: auto;
}

#about {
  margin-top: -0.4rem;
  background-color: #d0fa94;
}

#about img {
  width: 9rem;
  display: flex;
  margin-inline: auto;
  margin-bottom: 3.12rem;
}

#about p {
  font-size: 0.75rem;
  line-height: 150%;
  font-weight: 400;
  color: var(--tamia-black);
  padding: 1.25rem;
}

#about h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 150%;
  color: var(--tamia-black);
  padding: 0.62rem;
  margin-bottom: 0.62rem;
  font-weight: 800;
  font-family: 'Gunar Thin';
}

#about h2 {
  font-weight: 800;
  font-size: 1rem;
  line-height: 38px;
}

#hero-3 {
  text-align: center;
  background: url(./assets/background_hero3.png) center no-repeat, #fff;
  background-size: cover;
}
#hero-3 img {
  position: relative;
  height: 9rem;
  display: block;
  margin-inline: auto;
  bottom: 5px;
  left: 22px;
}
#hero-3 h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 800;
  backdrop-filter: blur(2px);
  margin: 10px;
  padding: 10px;
  font-family: 'Gunar Thin';
}
#hero-3 p {
  margin: 10px;
  letter-spacing: 0.1em;
}

/*=== SERVICES =================================*/
#services {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url(./assets/background_hero6.png) no-repeat, #e4e4e4;
  background-size: cover;
}

#services a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  background-color: #21211a;
  color: #d0f494;
  border-radius: 10px;
  margin-top: 20px;
}

#services .wrapper {
  text-align: center;
}

#cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.card {
  width: 220px;
  margin: 10px;
  padding: 20px;
  text-align: center;
  font-weight: 700;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
}

.card-title {
  margin-bottom: 10px;
  color: rgba(156, 219, 55, 1);
  font-size: 15px;
}

.card p {
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
}

/*HERO 5==================================*/
#hero-5 {
  background-color: var(--tamia-black);
}
#hero-5 .col-b {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 2rem;
}
.col-a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 10px;
}

#hero-5 h1 {
  font-weight: 800;
  margin-left: 1.25rem;
  color: var(--tamia-hard-green);
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  font-family: 'Gunar Thin';
}

#hero-5 p {
  color: var(--tamia-white);
  width: 22rem;
  margin: 10px;
  padding: 0 40px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.custom-number {
  display: inline-block;
  width: 1px;
  text-align: left;
  color: #9cdb37;
  font-size: 50px;
  font-weight: 600;
}

.custom-row {
  display: flex;
  align-items: center;
  margin: 20px 0px;
}

#hero-5 a {
  margin: 20px;
  color: #21211a;
  border-radius: 10px;
  padding: 7px 20px;
}

/*HERO 6==================================*/
#blog {
  background-color: var(--primary-color);
  text-align: center;
}

.image-container {
  margin: 30px 0;
}
.image-row {
  flex-direction: column;
  align-items: center;
}
#blog img {
  width: 200px;
  margin: 20px;
  border-radius: 20px;
}
#blog h3 {
  font-weight: 700;
  font-size: 18px;
}
#blog h1 {
  font-weight: 600;
  font-size: 25px;
  line-height: 68px;
  text-align: center;
  font-family: 'Gunar Thin';
}
#blog h4 {
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: #21211a;
}

#blog a {
  padding: 10px;
  background-color: #21211a;
  color: #d0f494;
  border-radius: 10px;
  width: 100px;
}
#blog a:hover {
  background-color: #000;
  transition: 0.3s;
}

#blog p {
  margin-bottom: 30px;
  padding: 5px 40px;
}
/*====FAQ====================================*/

#faq {
  background-color: var(--tamia-white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#faq h1 {
  margin: 1.25rem 0 3.75rem 0;
  font-size: 1.25rem;
  text-align: left;
  font-weight: 600;
  padding-bottom: 4px;
  border-bottom: 1px solid var(--tamia-hard-green);
  font-family: 'Gunar Thin';
}

#faq #more-faq {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.87rem;
  text-decoration: none;
  height: 2.5rem;
  font-size: 0.75rem;
  padding: 10px;
  background-color: #21211a;
  color: #d0f494;
  border-radius: 10px;
  width: 130px;
  font-weight: 700;
}
#faq #more-faq:hover {
  background-color: #000;
  transition: 0.3s;
}
.more-faq-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#page-faq {
  margin-bottom: 0.125rem;
  font-size: 2.5rem;
  font-weight: 500;
  text-align: left;
  padding-bottom: 4px;
  border-bottom: 2px solid var(--tamia-hard-green);
  margin-top: 5rem;
}

/*=== FOOTER =================================*/
#contato {
  padding: 20px;
  background-color: #21211a;
}

#contato h1 {
  display: flex;
  color: #9cdb37;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  font-family: 'Gunar Thin';
}
#contato a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 30px;
}
#contato .logo {
  width: 100px;
}

.social-logo {
  margin-right: 10px;
}

.footer-column {
  flex-basis: 100%;
}

.footer-title {
  font-size: 20px;
  text-align: center;
  color: #fff;
}

.footer-text {
  font-size: 12px;
  text-align: center;
  color: #fff;
}

/* BACK TO TOP */
#backToTopButton {
  position: fixed;
  bottom: 0.62rem;
  right: 1.56rem;
  opacity: 0;
  visibility: hidden;
  transform: translateY(100%);
  transition: 200ms;
}

#backToTopButton.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/*=== RESPONSIVE ==========================================*/
@media (min-width: 1024px) {
  /*=== GERAL =================================*/
  body {
    overflow: auto;
  }

  .wrapper {
    width: min(100rem, 100%);
    display: grid;
  }

  .col-a {
    grid-area: A;
  }

  .col-b {
    grid-area: B;
    height: 80vh;
  }

  section {
    padding-block: 4.25rem;
  }

  section header h2 {
    font-size: 2.5rem;
    line-height: 3.25rem;
  }

  /*=== NAVIGATION =================================*/
  /* reset */
  #navigation .wrapper * {
    margin: 0;
    padding: 0;
    visibility: initial;
    opacity: initial;
    flex-direction: initial;
    position: initial;
    font-size: initial;
    transform: initial;
    color: initial;
    background-color: initial;
    filter: initial;
  }

  #navigation .wrapper {
    justify-content: space-around;
  }

  #navigation .close-menu,
  #navigation .open-menu {
    display: none;
  }

  #navigation .menu {
    display: flex;
    align-items: center;
  }

  #navigation .menu .nav-menu-center {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8rem;
    flex-direction: row;
    white-space: nowrap;
  }

  #navigation #logo-header {
    width: 18rem;
    height: auto;
  }

  #navigation .menu ul:nth-child(1) {
    display: flex;
    gap: 2rem;
  }

  #navigation .menu ul li a {
    color: var(--tamia-black);
    font-size: 0.85rem;
    line-height: normal;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  #navigation .button {
    border: 2px solid var(--tamia-black);
    color: var(--tamia-black);
    padding: 0.45rem;
    font-size: 0.9rem;
    width: 6.25rem;
    margin: 0.62rem;
    min-width: 6.25rem;
    font-weight: 700;
    border-radius: 10px;
  }

  #navigation .button:hover {
    color: #000;
    transition: 0.3s;
    border: 2px solid #000;
    background-color: #f0fcd6;
  }

  #navigation .button-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #21211a;
    font-size: 0.8rem;
    border: none;
    width: 6.25rem;
    margin: 0.62rem;
    min-width: 6.25rem;
    font-weight: 700;
    color: #d0fa94;
    border-radius: 10px;
  }

  #navigation .button-contact:hover {
    background-color: #383838;
    transition: 0.3s;
  }

  #navigation .button-container {
    display: flex;
  }

  #navigation .menu li a {
    transition: opacity 0.4s;
  }

  #navigation .menu li:not(:first-child) a.active,
  #navigation .menu li a:hover {
    opacity: 1;
    color: var(--tamia-hard-green);
    transition: 0.2s;
  }

  #navigation.scroll .menu li a::after {
    background-color: var(--tamia-hard-green);
  }

  #navigation.scroll .menu li a.active,
  #navigation.scroll .menu li a:hover {
    opacity: 1;
  }

  #navigation.scroll .menu a.button.active,
  #navigation.scroll .menu a.button:hover {
    background-color: var(--primary-color);
    filter: brightness(1.3);
    border: none;
    border: 2px solid var(--tamia-black);
  }

  #navigation ul.social-links {
    margin-left: 0.62rem;
    padding: 0.62rem;
    gap: 0.81rem;
  }

  /*=== HOME =================================*/

  #home {
    height: 98vh;
    padding-top: var(--nav-height);
    padding-block: 0;
  }

  #home .wrapper {
    display: flex;
    height: 100%;
  }

  #home .col-a {
    flex: 0 0 37.81rem;
    margin: 4rem 0 1rem 2rem;
    text-align: left;
    align-self: center;
  }

  #home .col-b {
    flex: 1 1 auto;
    height: 100%;
    display: flex;
    max-height: 100%;
  }
  #home .hero-img-1 {
    position: relative;
    width: 40vw;
    height: auto;
    right: 5vw;
    top: 2vh;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  #home h1 {
    margin: 0.62rem 1.87rem 0.62rem 0;
    width: 39.7rem;
    justify-content: center;
    font-weight: 600;
    font-size: 26px;
  }

  #home .hr-top {
    margin: 0;
  }

  #home .hr-bottom {
    bottom: 0;
  }

  #home .content p {
    font-size: 0.81rem;
    margin: 2.5rem 0;
    width: 30rem;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
  }

  #home .button {
    margin: 0;
    font-size: 18px;
  }

  #home img {
    margin-top: auto;
    width: 17rem;
  }

  /*===about===================================*/
  #about {
    margin-top: -10px;
    height: 100vh;
    padding: 0px;
  }
  #about .wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  #about .col-b {
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: center;
    order: 2;
    flex-grow: 1;
  }
  #about h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    font-size: 30px;
    line-height: 45px;
    padding-top: 5rem;
  }
  #about h2 {
    font-size: 25px;
  }

  #about p {
    font-size: 1.5em;
    width: 31.25rem;
    font-weight: 400;
    padding: 5px;
  }

  /*===Hero-3===================================*/
  #hero-3 {
    padding-top: var(--nav-height);
    height: 100vh;
  }

  #hero-3 .wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  #hero-3 .col-a {
    flex: 1;
  }

  #hero-3 img {
    width: 35vw;
    height: auto;
    bottom: 10vh;
    left: 10vw;
  }

  #hero-3 h1 {
    display: flex;
    font-size: 30px;
    line-height: 45px;
    color: #212121;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin: 0 30px;
  }

  #hero-3 p {
    text-align: left;
    width: 30rem;
    margin: 7rem 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.1em;
    color: #212121;
  }

  /*===Hero-5==================================*/

  #hero-5 {
    padding-top: var(--nav-height);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #hero-5 .wrapper {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .col-a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 60px;
  }

  .col-b {
    text-align: left;
  }

  #hero-5 h1 {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
  }

  #hero-5 p {
    width: 22rem;
    margin: 10px;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    padding: 0;
  }

  .custom-number {
    display: inline-block;
    width: 20px;
    text-align: left;
    color: #9cdb37;
    font-size: 60px;
    margin-right: 15px;
    font-weight: 600;
  }

  .custom-row {
    display: flex;
    align-items: center;
    margin: 30px 0;
  }

  #hero-5 a {
    margin: 20px;
    color: #21211a;
    border-radius: 10px;
    padding: 7px 30px;
    font-size: 20px;
    font-weight: 700;
  }

  /*=== SERVICES =================================*/

  #services a {
    padding: 12px;
    width: 180px;
    margin-top: 10px;
  }

  #services h1 {
    font-weight: 600;
    font-size: 35px;
    line-height: 52px;
    color: #212121;
    margin-top: 120px;
  }
  .wrapper {
    text-align: center;
  }

  #cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 0;
    max-width: 960px;
    margin: 0 auto;
  }

  .card {
    width: calc(100% / 3 - 40px);
    margin: 20px;
    height: 180px;
    padding: 10px;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
  }

  .card-title {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 600;
    color: var(--tamia-hard-green);
  }

  .card p {
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
  }

  /*===blog===================================*/
  #blog h1 {
    margin: 0;
    font-size: 45px;
  }

  .image-row {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
  }
  .image-container {
    margin-top: 5px;
  }

  #blog img {
    width: 300px;
    margin: 30px 30px 20px 30px;
  }

  #blog p {
    margin-bottom: 35px;
    padding: 5px 40px;
    width: 25rem;
    height: 6rem;
    font-size: 17px;
  }

  #blog h4 {
    font-size: 25px;
  }
  /*====FAQ====================================*/

  #faq {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 100px;
  }
  #faq .wrapper {
    text-align: left;
  }
  #faq .wrapper {
    text-align: left;
  }

  #faq h1 {
    font-size: 1.31rem;
    text-align: left;
    margin-bottom: 6.25rem;
  }

  #faq button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    height: 3.75rem;
    background-color: transparent;
  }

  /*=== FOOTER =================================*/
  #contato {
    display: flex;
    flex-wrap: wrap;
    height: 22rem;
  }

  #contato h1 {
    width: 100%;
    text-align: center;
    padding-top: 10px;
  }

  #contato a {
    margin: 5px;
  }

  footer .columns {
    display: flex;
    width: 100%;
  }
  .footer-column {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }

  .footer-title {
    font-size: 24px;
  }

  .footer-text {
    margin-bottom: 10px;
    font-size: 12px;
    text-align: center;
    width: 13.5rem;
  }

  .social-logo {
    margin-right: 10px;
  }
}
